import React, { useState } from 'react';

import {
  LoginPageProps,
  LoginFormTypes,
  useLogin,
  useTranslate,
  useLink,
  BaseRecord,
  HttpError,
} from '@refinedev/core';

import { FormProvider } from 'react-hook-form';

import { useForm } from '@refinedev/react-hook-form';
import { SubmitHandler } from 'react-hook-form';
import { ProviderButtons } from './ProviderButtons';
import { FormPropsType } from '..';
import Input from '@components/Input';
import Alert from '@components/Alert';
import Loading from '@components/Loading';
import { Button } from '@components/Button';

type DivPropsType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type LoginProps = LoginPageProps<
  DivPropsType,
  DivPropsType,
  FormPropsType<LoginFormTypes>
>;

export const LoginPage: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
}) => {
  const { onSubmit: submitForm, ...useFormProps } = formProps || {};

  const Link = useLink();
  const methods = useForm<BaseRecord, HttpError, LoginFormTypes>({
    ...useFormProps,
  });

  const {
    refineCore: { onFinish },
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const translate = useTranslate();

  const { mutate: login, isLoading, data } = useLogin<LoginFormTypes>();

  const onSubmit: SubmitHandler<LoginFormTypes> = (values) => {
    if (submitForm) {
      return submitForm(values);
    }
    login(values);
    return onFinish(values);
  };
  const content = (
    <div
      {...contentProps}
      className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-base-content">
          {translate('pages.login.title', 'Sign in to your account')}
        </h2>
        {registerLink ?? (
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link
              to={'/register'}
              className="font-medium text-primary hover:text-primary-focus"
            >
              {translate('pages.login.register', 'start your free trial')}
            </Link>
          </p>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={handleSubmit(onSubmit) as any}
            {...formProps}
          >
            <Input
              disabled={isLoading}
              label={translate('pages.login.fields.email', 'Email')}
              type="email"
              autoComplete="email"
              autoCorrect="off"
              spellCheck={false}
              autoCapitalize="off"
              aria-invalid={errors.email ? 'true' : 'false'}
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: translate(
                    'pages.login.errors.validEmail',
                    'Invalid email address'
                  ),
                },
              })}
              error={
                (errors.email?.type === 'required' &&
                  translate('pages.login.errors.required', {
                    item: translate('pages.login.fields.email'),
                  })) ||
                ((errors.email?.message?.length ?? 0) > 0 &&
                  errors.email?.message)
              }
            />
            <Input
              disabled={isLoading}
              label={translate('pages.login.fields.password', 'Password')}
              type="password"
              autoComplete="password"
              {...register('password', {
                required: true,
              })}
              error={
                errors.password?.type === 'required' &&
                translate('pages.login.errors.required', {
                  item: translate('pages.login.fields.password'),
                })
              }
            />

            {!isLoading && data?.error && (
              <Alert type="error" title="Unable to sign in">
                {data?.error.message}
              </Alert>
            )}

            <div className="flex items-center justify-between">
              {rememberMe ?? (
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    {...register('remember')}
                    className="checkbox h-4 w-4 rounded border-gray-300 text-primary"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    {translate('pages.login.fields.rememberMe', 'Remember me')}
                  </label>
                </div>
              )}

              {forgotPasswordLink ?? (
                <div className="text-sm">
                  <Link
                    to={'/forgot-password'}
                    className="font-medium text-primary hover:text-primary-focus"
                  >
                    {translate(
                      'pages.login.forgotPassword',
                      'Forgot password?'
                    )}
                  </Link>
                </div>
              )}
            </div>

            <div>
              <Button
                color="primary"
                fullWidth
                size="md"
                loading={isLoading || data?.success}
                disabled={isLoading}
                type="submit"
              >
                {translate('pages.login.signin', 'Sign in')}
              </Button>
            </div>
          </form>
          <ProviderButtons callback={login} providers={providers} />
        </div>
      </div>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <div {...wrapperProps}>
        {renderContent ? renderContent(content, 'Login') : content}
      </div>
    </FormProvider>
  );
};
