import React from 'react';

import {
  BaseRecord,
  HttpError,
  RegisterFormTypes,
  RegisterPageProps,
  useLink,
  useRegister,
  useTranslate,
} from '@refinedev/core';

import Input from '@components/Input';
import { useForm } from '@refinedev/react-hook-form';
import { SubmitHandler } from 'react-hook-form';
import { FormPropsType } from '..';
import { ProviderButtons } from './ProviderButtons';
import Alert from '@components/Alert';
import Loading from '@components/Loading';
import { Button } from '@components/Button';
type DivPropsType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface RegisterFormVariables extends RegisterFormTypes {
  name: string;
}

type RegisterProps = RegisterPageProps<
  DivPropsType,
  DivPropsType,
  FormPropsType<RegisterFormVariables>
>;

export const RegisterPage: React.FC<RegisterProps> = ({
  providers,
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
}) => {
  const { onSubmit: submitForm, ...useFormProps } = formProps ?? {};

  const Link = useLink();

  const {
    refineCore: { onFinish },
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BaseRecord, HttpError, RegisterFormVariables>({
    ...(useFormProps as any),
  });

  const translate = useTranslate();
  const {
    mutate: registerUser,
    isLoading,
    data,
  } = useRegister<RegisterFormVariables>();

  const onSubmit: SubmitHandler<RegisterFormVariables> = (values) => {
    if (submitForm) {
      return submitForm(values);
    }
    registerUser(values);
    return onFinish(values);
  };

  console.log(errors);

  const content = (
    <div
      {...contentProps}
      className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {translate('pages.register.title', 'Sign up for your account')}
        </h2>
        {loginLink ?? (
          <p className="mt-2 text-center text-sm text-gray-600">
            Or {translate('pages.register.haveAccount', 'Have an account?')}
            <Link
              to={'/login'}
              className="font-medium text-primary hover:text-primary-focus"
            >
              {translate('pages.register.login', 'Sign in')}
            </Link>
          </p>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={handleSubmit(onSubmit) as any}
            {...formProps}
          >
            <Input
              type="text"
              disabled={isLoading}
              label={translate('pages.register.fields.fullName', 'Full Name')}
              {...register('name', {
                required: true,
                minLength: 3,
              })}
              error={
                errors.name?.type === 'required' &&
                translate('pages.register.errors.required', {
                  item: translate('pages.register.fields.fullName'),
                })
              }
              aria-invalid={errors.name ? 'true' : 'false'}
            />

            <Input
              disabled={isLoading}
              label={translate('pages.register.fields.email', 'Email')}
              type="email"
              autoComplete="email"
              autoCorrect="off"
              spellCheck={false}
              autoCapitalize="off"
              aria-invalid={errors.email ? 'true' : 'false'}
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: translate(
                    'pages.register.errors.validEmail',
                    'Invalid email address'
                  ),
                },
              })}
              error={
                (errors.email?.type === 'required' &&
                  translate('pages.register.errors.required', {
                    item: translate('pages.register.fields.email'),
                  })) ||
                ((errors.email?.message?.length ?? 0) > 0 &&
                  errors.email?.message)
              }
            />

            <Input
              disabled={isLoading}
              label={translate('pages.register.fields.password', 'Password')}
              type="password"
              autoComplete="password"
              {...register('password', {
                required: true,
                minLength: 8,
              })}
              error={
                (errors.password?.type === 'required' &&
                  translate('pages.register.errors.required', {
                    item: translate('pages.register.fields.password'),
                  })) ||
                (errors.password?.type === 'minLength' &&
                  translate('pages.register.errors.minLength', {
                    item: translate('pages.register.fields.password'),
                    value: 8,
                  }))
              }
            />
            {!isLoading && data?.error && (
              <Alert type="error" title="Unable to create user">
                {data?.error.message}
              </Alert>
            )}
            <div>
              <Button
                color="primary"
                fullWidth
                size="md"
                loading={isLoading || data?.success}
                disabled={isLoading || data?.success}
                type="submit"
              >
                {translate('pages.register.signup', 'Sign up')}
              </Button>
            </div>
          </form>
          <ProviderButtons callback={registerUser} providers={providers} />
        </div>
      </div>
    </div>
  );

  return (
    <div {...wrapperProps}>
      {renderContent ? renderContent(content, 'Register') : content}
    </div>
  );
};
