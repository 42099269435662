import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';

interface AlertProps {
  type?: 'warning' | 'error' | 'success' | 'info' | 'custom';
  onClose?: () => void;
  title?: ReactNode;
  className?: string;
}
export default function Alert({
  type: alertType = 'info',
  title,
  onClose,
  children,
  className,
}: PropsWithChildren<AlertProps>) {
  return (
    <div
      className={clsx(
        'rounded-md p-4',
        {
          'bg-yellow-50': alertType === 'warning',
          'bg-red-50': alertType === 'error',
          'bg-green-50': alertType === 'success',
          'bg-blue-50': alertType === 'info',
        },
        className
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {alertType === 'error' && (
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          )}
          {alertType === 'success' && (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          )}
          {alertType === 'warning' && (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          )}
          {alertType === 'info' && (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className={clsx('flex-1', { 'ml-3': alertType !== 'custom' })}>
          {title ? (
            <h3
              className={clsx(
                {
                  'text-yellow-800': alertType === 'warning',
                  'text-red-800': alertType === 'error',
                  'text-green-800': alertType === 'success',
                  'text-blue-800': alertType === 'info',
                },
                'text-sm font-medium'
              )}
            >
              {title}
            </h3>
          ) : null}
          {children && (
            <div
              className={clsx(
                {
                  'mt-2': title,
                  'text-yellow-700': alertType === 'warning',
                  'text-red-700': alertType === 'error',
                  'text-green-700': alertType === 'success',
                  'text-blue-700': alertType === 'info',
                },
                'text-sm'
              )}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
