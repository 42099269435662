import { Button } from '@components/Button';
import { OAuthProvider } from '@refinedev/core';
import clsx from 'clsx';
import Image from 'next/image';
import GoogleBtn from './btn_google_signin_light_normal.png';
import GoogleHoveredBtn from './btn_google_signin_light_focus.png';
import GoogleActive from './btn_google_signin_light_pressed.png';

const providerBtnMap = {
  google: (props) => (
    <button {...props} className="group mx-auto">
      <Image
        src={GoogleBtn}
        className="inline-block max-h-12 w-auto group-hover:hidden group-active:hidden"
        alt="Sign in with google"
        priority
        rel="preload"
      />
      <Image
        src={GoogleHoveredBtn}
        className="hidden max-h-12 w-auto group-hover:inline-block group-active:!hidden"
        alt="Sign in with google"
        priority
        rel="preload"
      />
      <Image
        src={GoogleActive}
        className="hidden max-h-12 w-auto group-active:inline-block"
        rel="preload"
        alt="Sign in with google"
        priority
      />
    </button>
  ),
};

export function ProviderButtons({
  providers,
  callback,
}: {
  providers?: OAuthProvider[];
  callback(variables: Object): void;
}) {
  if (providers) {
    return (
      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="bg-white px-2 text-gray-500">
              Or continue with
            </span>
          </div>
        </div>
        <div className="mt-6 flex flex-col gap-3">
          {providers.map((provider) => {
            const Comp = providerBtnMap[provider.name] ?? Button;
            return (
              <div className="mx-auto" key={provider.name}>
                <Comp
                  onClick={() =>
                    callback({
                      providerName: provider.name,
                    })
                  }
                >
                  {provider.label ?? (
                    <span className="sr-only">{provider.label}</span>
                  )}
                  {provider?.icon}
                </Comp>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
}
