import React, { useState } from 'react';
import {
  BaseRecord,
  HttpError,
  UpdatePasswordFormTypes,
  UpdatePasswordPageProps,
  useParsed,
  useTranslate,
  useUpdatePassword,
} from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { FormPropsType } from '..';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import Input from '@components/Input';
import Alert from '@components/Alert';
import { Button } from '@components/Button';

type DivPropsType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface ResetPasswordFormTypes extends UpdatePasswordFormTypes {
  userId: string;
  secret: string;
}

type UpdatePasswordProps = UpdatePasswordPageProps<
  DivPropsType,
  DivPropsType,
  FormPropsType<ResetPasswordFormTypes>
>;

export const UpdatePasswordPage: React.FC<UpdatePasswordProps> = ({
  title,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
}) => {
  const { params } = useParsed();
  const translate = useTranslate();
  const { onSubmit: submitForm, ...useFormProps } = formProps || {};

  const methods = useForm<BaseRecord, HttpError, UpdatePasswordFormTypes>({
    ...useFormProps,
  });

  const {
    refineCore: { onFinish },
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const {
    mutate: updatePassword,
    data,
    isLoading,
  } = useUpdatePassword<ResetPasswordFormTypes>();

  const onSubmit: SubmitHandler<ResetPasswordFormTypes> = (values) => {
    if (submitForm) {
      return submitForm(values);
    }
    updatePassword({
      ...values,
      secret: params?.secret ?? '',
      userId: params?.userId ?? '',
    });
    return onFinish(values);
  };

  const content = (
    <div
      {...contentProps}
      className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {translate('pages.updatePassword.title', 'Reset your password?')}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={handleSubmit(onSubmit) as any}
            {...formProps}
          >
            <Input
              disabled={isLoading}
              label={translate(
                'pages.updatePassword.fields.password',
                'Password'
              )}
              type="password"
              autoComplete="password"
              {...register('password', {
                required: true,
              })}
              error={
                errors.password?.type === 'required' &&
                translate('pages.login.errors.required', {
                  item: translate('pages.updatePassword.fields.password'),
                })
              }
            />

            <Input
              disabled={isLoading}
              label={translate(
                'pages.updatePassword.fields.password',
                'Confirm Password'
              )}
              type="password"
              autoComplete="password"
              {...register('confirmPassword', {
                required: true,
              })}
              error={
                errors.confirmPassword?.type === 'required' &&
                translate('pages.updatePassword.errors.required', {
                  item: translate(
                    'pages.updatePassword.fields.confirmPassword'
                  ),
                })
              }
            />

            {!isLoading && data?.error && (
              <Alert type="error" title="Unable to reset password">
                {data?.error.message}
              </Alert>
            )}

            <div>
              <Button
                color="primary"
                fullWidth
                size="md"
                loading={isLoading || data?.success}
                disabled={isLoading || data?.success}
                type="submit"
              >
                {translate('pages.updatePassword.button', 'Reset')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <div {...wrapperProps}>
        {renderContent ? renderContent(content, 'Reset Password') : content}
      </div>
    </FormProvider>
  );
};
