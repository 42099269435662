import React, { useState } from 'react';
import {
  useTranslate,
  useForgotPassword,
  ForgotPasswordFormTypes,
  ForgotPasswordPageProps,
  useLink,
  BaseRecord,
  HttpError,
} from '@refinedev/core';
import { FormPropsType } from '..';
import { useForm } from '@refinedev/react-hook-form';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import Input from '@components/Input';
import { Button } from '@components/Button';
import Alert from '@components/Alert';

type DivPropsType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type ForgotPasswordProps = ForgotPasswordPageProps<
  DivPropsType,
  DivPropsType,
  FormPropsType<ForgotPasswordFormTypes>
>;

export const ForgotPasswordPage: React.FC<ForgotPasswordProps> = ({
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
}) => {
  const translate = useTranslate();
  const { onSubmit: submitForm, ...useFormProps } = formProps || {};

  const Link = useLink();

  const methods = useForm<BaseRecord, HttpError, ForgotPasswordFormTypes>({
    ...useFormProps,
  });

  const {
    refineCore: { onFinish },
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const {
    mutate: forgotPassword,
    data,
    isLoading,
  } = useForgotPassword<ForgotPasswordFormTypes>();

  const onSubmit: SubmitHandler<ForgotPasswordFormTypes> = (values) => {
    if (submitForm) {
      return submitForm(values);
    }
    forgotPassword(values);
    return onFinish(values);
  };

  const renderLink = (link: React.ReactNode, text?: string) => {
    if (link) {
      if (typeof link === 'string') {
        return (
          <Link to={link} className="link-primary link">
            {text}
          </Link>
        );
      }
      return link;
    }
    return null;
  };

  const content = (
    <div
      {...contentProps}
      className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {translate('pages.forgotPassword.title', 'Forgot your password?')}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={handleSubmit(onSubmit) as any}
            {...formProps}
          >
            <Input
              disabled={isLoading}
              label={translate('pages.forgotPassword.fields.email', 'Email')}
              type="email"
              autoComplete="email"
              autoCorrect="off"
              spellCheck={false}
              autoCapitalize="off"
              aria-invalid={errors.email ? 'true' : 'false'}
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: translate(
                    'pages.forgotPassword.errors.validEmail',
                    'Invalid email address'
                  ),
                },
              })}
              error={
                (errors.email?.type === 'required' &&
                  translate('pages.forgotPassword.errors.required', {
                    item: translate('pages.forgotPassword.fields.email'),
                  })) ||
                ((errors.email?.message?.length ?? 0) > 0 &&
                  errors.email?.message)
              }
            />

            {!isLoading && data?.error && (
              <Alert type="error" title={data?.error.message}></Alert>
            )}
            <div>
              <Button
                size="md"
                fullWidth
                color="primary"
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                {translate(
                  'pages.forgotPassword.submit',
                  'Send reset instructions'
                )}
              </Button>
            </div>
          </form>
          <div className="divider">OR</div>
          {loginLink ?? (
            <div className="text-sm">
              {translate('pages.register.haveAccount', 'Have an account? ')}
              {'  '}
              {renderLink('/login', translate('pages.login.signin', 'Sign in'))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <div {...wrapperProps}>
        {renderContent ? renderContent(content, 'Forgot Password') : content}
      </div>
    </FormProvider>
  );
};
