import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { AuthPageProps } from '@refinedev/core';
import { UseFormProps } from '@refinedev/react-hook-form';
import { RegisterPage } from './components/register';
import { ForgotPasswordPage } from './components/forgotPassword';
import { UpdatePasswordPage } from './components/updatePassword';
import { LoginPage } from './components/login';
import dynamic from 'next/dynamic';

export type DivPropsType = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export interface FormPropsType<TFormType> extends UseFormProps {
  onSubmit?: (values: TFormType) => void;
}

export type AuthProps<T> = AuthPageProps<
  DivPropsType,
  DivPropsType,
  FormPropsType<T>
>;
export const AuthRedirect = dynamic(
  () => import('./components/LoggedInRedirect'),
  {
    ssr: false,
  }
);

/**
 * **refine** has a default auth page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#authpage} for more details.
 */
export const AuthPage: React.FC<AuthProps<any>> = (props) => {
  const { type } = props;
  const renderView = () => {
    switch (type) {
      case 'register':
        return <RegisterPage {...props} />;
      case 'forgotPassword':
        return <ForgotPasswordPage {...props} />;
      case 'updatePassword':
        return <UpdatePasswordPage {...props} />;
      default:
        return <LoginPage {...props} />;
    }
  };

  return (
    <>
      <AuthRedirect />
      {renderView()}
    </>
  );
};
