import Loading from '@components/Loading';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  DetailedHTMLProps,
  forwardRef,
  FunctionComponent,
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
} from 'react';

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: ReactNode;
  error?: ReactNode;
  helpText?: ReactNode;
  loading?: boolean;
  containerProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  Icon?: FunctionComponent<{ className: string }>;
  iconProps?: Record<string, any>;
}

export default forwardRef(function Input(
  {
    Icon,
    label,
    error,
    loading,
    containerProps,
    iconProps: iconProps_,
    helpText,
    className,
    ...inputProps
  }: InputProps,
  ref: any
) {
  const { iconClassName, ...iconProps } = iconProps_ ?? {};
  return (
    <div
      {...containerProps}
      className={clsx(containerProps?.className, 'form-control w-full')}
    >
      {label ? (
        <label htmlFor={inputProps.name} className="label py-1">
          <span className="label-text text-xs">{label}</span>
        </label>
      ) : null}
      <div className="relative">
        {Icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <Icon
              className={clsx(
                {
                  'text-error': error,
                },
                'h-5 w-5',
                iconClassName
              )}
              aria-hidden="true"
              {...iconProps}
            />
          </div>
        )}
        <input
          ref={ref}
          {...inputProps}
          className={clsx(
            {
              'input-error': error,
              '!pl-10': Icon,
              '!pr-10': error || loading,
            },
            'input-bordered input input-sm w-full',
            className
          )}
        />
        {loading && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
            <Loading />
          </div>
        )}
        {error ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-error"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>

      {error ? (
        <label className="label py-1">
          <span className="label-text-alt text-xs text-error">{error}</span>
        </label>
      ) : (
        helpText && (
          <label className="label py-1">
            <span className="label-text-alt text-xs">{helpText}</span>
          </label>
        )
      )}
    </div>
  );
});
